import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { Divider } from "antd-min";
import { EventModalContentProps, CampusMandatoryAttributeModel, PendingChangePropsModel } from '@app/service/cims';
import { fmtMsg, CampusChangeField } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { CampusAttribute2DisplayLabel } from './cims-consts';
import { MandatoryAttributeTable } from './mandatory-attribute-table';

export const CampusMandatoryAttributeComparer: React.FC<EventModalContentProps<CampusMandatoryAttributeModel>> = (props: EventModalContentProps<CampusMandatoryAttributeModel>) => {
    const { item } = props;
    const data = Array.isArray(item) && item.length > 0 ? item[0] : item;
    const previousCampus = data.previousCampus ? data.previousCampus : {...data};
    const currentCampus = data.currentCampus ? data.currentCampus : {...data}
    const changedFields = data.changeFields;
    const nameChanged = previousCampus && ((changedFields && changedFields.includes(CampusChangeField.Name)) 
                                                || (!changedFields && previousCampus.name != currentCampus.name));
    const phoneChanged = previousCampus && ((changedFields && changedFields.includes(CampusChangeField.Phone))
                                                || (!changedFields && previousCampus.phone != currentCampus.phone));
    const cityChanged = previousCampus && ((changedFields && changedFields.includes(CampusChangeField.City))
                                                || (!changedFields && previousCampus.city != currentCampus.city));
    const postalCodeChanged = previousCampus && ((changedFields && changedFields.includes(CampusChangeField.PostalCode))
                                                || (!changedFields && previousCampus.postalCode != currentCampus.postalCode));
    const fullAddressChanged = previousCampus && ((changedFields && changedFields.includes(CampusChangeField.FullAddress))
                                                || (!changedFields && previousCampus.fullAddress != currentCampus.fullAddress));
    const licenseListChanged = previousCampus && ((changedFields && changedFields.includes(CampusChangeField.LicenseList))
                                                || (!changedFields && previousCampus.nexusLicenseType != currentCampus.nexusLicenseType));
    function isMandatoryAttribute(attribute) {
        return (nameChanged && attribute == PendingChangePropsModel.name) 
            || (phoneChanged && attribute == PendingChangePropsModel.phone)
            || (cityChanged && attribute == PendingChangePropsModel.city)
            || (postalCodeChanged && attribute == PendingChangePropsModel.postalCode)
            || (fullAddressChanged && attribute == PendingChangePropsModel.fullAddress) 
            || (licenseListChanged && attribute == PendingChangePropsModel.licenseList);
    }
    function formatMandatoryAttribute(preCampusInfo, curCampusInfo) {
        return Object.keys(preCampusInfo).filter(key => isMandatoryAttribute(key))
                    .map((attribute, index) => {
                        return {
                            index, 
                            preMandatoryAttribute: fmtMsg({id: CampusAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}), 
                            preMandatoryAttributeValue: preCampusInfo[attribute], 
                            curMandatoryAttribute: fmtMsg({id: CampusAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}), 
                            curMandatoryAttributeValue: curCampusInfo[attribute]                            
                }});
    }
    return (
        <div className='mandatory-attribute-comparer'>
            <FormItemsLayout colTotal={2} >
                <div className='mandatory-attribute-comparer-divider mandatory-attribute-comparer-divider-with-table'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventPrevious})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrent})}</Divider>
                </div>
            </FormItemsLayout>
            <MandatoryAttributeTable dataSource={formatMandatoryAttribute(previousCampus, currentCampus)} />
        </div>
    )
}