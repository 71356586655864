import React, { Component } from 'react';
import { List, Table } from "antd-min";
import { ArrowRightIcon } from '@app/components/svgicon';

export const MandatoryAttributeTable = ({dataSource, className=null, columnWidth=['15%', '30%', '10%', '15%', '30%']}) => {
    return (
        <Table
            rowKey='index'
            className={className}
            showHeader={false}
            pagination={false}
            scroll={{y: 200}}
            dataSource={dataSource}
            columns={[
                {
                    title: '',
                    dataIndex: "preMandatoryAttribute",
                    width: columnWidth[0],
                    className: 'mandatory-attribute pre-mandatory-attribute'
                }, 
                {
                    title: '',
                    dataIndex: "preMandatoryAttributeValue",
                    render: (text, dataSource, index) => {
                        if(dataSource.preMandatoryAttribute === "License Type"){
                            const licenses = dataSource.preMandatoryAttributeValue.split(',').map(license => license.trim());
                            return <List
                                size="small"
                                dataSource={licenses}
                                renderItem={(item) => <List.Item>{item}</List.Item>}
                            />
                        }
                        return text;
                    },
                    width: columnWidth[1]
                }, 
                {
                    title: '',
                    dataIndex: "index",
                    width: columnWidth[2],
                    className: 'comparer-arrow-column',
                    render: (text, record, index) => {
                        return {
                            children: <ArrowRightIcon className='previous-current-comparer-arrow previous-current-comparer-arrow-in-table' />,
                            props: {
                              rowSpan: index == 0 ? dataSource.length : 0,
                            },
                          };
                    }
                }, 
                {
                    title: '',
                    dataIndex: "curMandatoryAttribute",
                    width: columnWidth[3],
                    className: 'mandatory-attribute'
                }, 
                {
                    title: '',
                    dataIndex: "curMandatoryAttributeValue",
                    render: (text, dataSource, index) => {
                        if(dataSource.curMandatoryAttribute === "License Type"){
                            const licenses = dataSource.curMandatoryAttributeValue.split(',').map(license => license.trim());
                            return <List
                                size="small"
                                dataSource={licenses}
                                renderItem={(item) => <List.Item>{item}</List.Item>}
                            />
                        }
                        return text;
                    },
                    width: columnWidth[4]
                }
            ]}
        />
    )
}