import React, { Component } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Modal, Form, Spin, message, Input, Checkbox, Button } from "antd-min";
import { ValidationRule } from "antd/lib/form";
import maxBy from "lodash/maxBy";
import {
    FormItemsLayout,
    GLLocale,
    GLFormComponentProps,
    GLForm,
    FormHelper,
    ComparisonOperator,
    connect,
    StateType,
    GLUtil
} from "gl-commonui";
import {
    fmtMsg,
    PendingChangeField,
    textareaValider,
    ChangeLogType,
    SchoolChangeField,
    CampusChangeField,
    SchoolEventType,
    CampusEventType,
    EventType,
    MaterialOrderStatus,
    EventInfo
} from "@app/util";
import {
    ApproveDenyModalContentProps,
    ChangeEventModel,
    ChangeEventPropsModel
} from "@app/service/cims";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { ArrowUpIcon, ArrowDownIcon } from "@app/components/svgicon";
import {
    FormItem,
    FormItemLayout4ClassName,
    SchoolApproveDenyModalTitleIds,
    CampusApproveDenyModalTitleIds,
    ApproveDenyModalTitleIds,
    ApproveDenyModalHideClassName,
    SchoolApproveDenyModalOptinalStyle,
    CampusApproveDenyModalOptinalStyle,
    ApproveDenyModalOptinalStyle,
    FormItemLayout4ApproveDenyTitle,
    canApproveDeny4ChangeType,
    ApproveDenyCampusModalHideClassName
} from "./cims-consts";
import { ClassInfo } from "./class-info";
import { MandatoryAttributeComparer } from "./mandatory-attribute-comparer";
import { UnitPlan4ApproveDeny } from "./unit-plan-approve-deny";
import { LicenseChange4ApproveDeny } from "./license-change-approve-deny";
import { SchoolMandatoryAttributeComparer } from "./school-mandatory-attribute-comparer";
import { CampusMandatoryAttributeComparer } from "./campus-mandatory-attribute-comparer";
import { CampusInfo } from "./campus-info";
import { PromoteClassComparer } from "./promote-class-comparer";
import { MaterialInfo } from "./material-info";
import { MaterialOrderModel } from "@app/service/material";
import { Link } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import { MovePromoteStudentsCimsInfo } from "./move-promote-students-cims-info";

// const SchoolApproveDenyModalContents: Map<SchoolChangeField, React.FC<ApproveDenyModalContentProps<ChangeEventModel>>> = new Map([
//     [SchoolChangeField.Name, SchoolMandatoryAttributeComparer],
//     [SchoolChangeField.EnglishName, SchoolMandatoryAttributeComparer],
// ]);

// const CampusApproveDenyModalContents: Map<CampusChangeField, React.FC<ApproveDenyModalContentProps<ChangeEventModel>>> = new Map([
//     [CampusChangeField.AddCampus, CampusInfo],
//     [CampusChangeField.DisableCampus, CampusInfo],
//     [CampusChangeField.EnableCampus, CampusInfo],
//     [CampusChangeField.Name, CampusMandatoryAttributeComparer],
//     [CampusChangeField.Phone, CampusMandatoryAttributeComparer],
//     [CampusChangeField.City, CampusMandatoryAttributeComparer],
//     [CampusChangeField.PostalCode, CampusMandatoryAttributeComparer],
//     [CampusChangeField.AddressLine1, CampusMandatoryAttributeComparer],
// ]);

// const ApproveDenyModalContents: Map<PendingChangeField, React.FC<ApproveDenyModalContentProps<ChangeEventModel>>> = new Map([
//     [PendingChangeField.AddClass, ClassInfo],
//     [PendingChangeField.DisableClass, ClassInfo],
//     [PendingChangeField.EnableClass, ClassInfo],
//     [PendingChangeField.StudentCount, LicenseChange4ApproveDeny],
//     [PendingChangeField.MoveStudent, LicenseChange4ApproveDeny],
//     [PendingChangeField.RemoveStudent, LicenseChange4ApproveDeny],
//     [PendingChangeField.ChangeStudentSubscription, LicenseChange4ApproveDeny],
//     [PendingChangeField.MoveUnregisteredStudent, LicenseChange4ApproveDeny],
//     [PendingChangeField.Grade, MandatoryAttributeComparer],
//     [PendingChangeField.StartDate, MandatoryAttributeComparer],
//     [PendingChangeField.EndDate, MandatoryAttributeComparer],
//     [PendingChangeField.StartUnit, MandatoryAttributeComparer],
//     [PendingChangeField.EndUnit, MandatoryAttributeComparer],
//     [PendingChangeField.TSILessonsPerWeek, MandatoryAttributeComparer],
//     [PendingChangeField.Plans, UnitPlan4ApproveDeny],
// ]);

const SchoolApproveDenyModalContents: Map<
    EventInfo,
    React.FC<ApproveDenyModalContentProps<ChangeEventModel>>
> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, SchoolMandatoryAttributeComparer],
    [EventInfo.PendingChangesMovePromoteStudents, MovePromoteStudentsCimsInfo]
]);

const CampusApproveDenyModalContents: Map<
    EventInfo,
    React.FC<ApproveDenyModalContentProps<ChangeEventModel>>
> = new Map([
    [EventInfo.PendingChangesAddCampus, CampusInfo],
    [EventInfo.PendingChangesDisableCampus, CampusInfo],
    [EventInfo.PendingChangesEnableCampus, CampusInfo],
    [EventInfo.PendingChangesChangeCampusMandatoryData, CampusMandatoryAttributeComparer],
    [EventInfo.PendingChangesMaterialOrder, MaterialInfo]
]);

const ApproveDenyModalContents: Map<
    EventInfo,
    React.FC<ApproveDenyModalContentProps<ChangeEventModel>>
> = new Map([
    [EventInfo.PendingChangesAddClass, ClassInfo],
    [EventInfo.PendingChangesPromoteClass, PromoteClassComparer],
    [EventInfo.PendingChangesDisableClass, ClassInfo],
    [EventInfo.PendingChangesEnableClass, ClassInfo],
    [EventInfo.PendingChangesChangeLicense, LicenseChange4ApproveDeny],
    [EventInfo.PendingChangesMoveStudent, LicenseChange4ApproveDeny],
    [EventInfo.PendingChangesMoveUnregisteredStudent, LicenseChange4ApproveDeny],
    [EventInfo.PendingChangesRemoveStudent, LicenseChange4ApproveDeny],
    [EventInfo.PendingChangesChangeClassMandatoryData, MandatoryAttributeComparer],
    [EventInfo.PendingChangesChangeClassUnitPlan, UnitPlan4ApproveDeny]
]);

export function getApproveDenyModalContents(target: ChangeLogType, changeType) {
    switch (target) {
        case ChangeLogType.School:
            return SchoolApproveDenyModalContents.has(changeType)
                ? SchoolApproveDenyModalContents.get(changeType)
                : null;
        case ChangeLogType.Campus:
            return CampusApproveDenyModalContents.has(changeType)
                ? CampusApproveDenyModalContents.get(changeType)
                : null;
        case ChangeLogType.SchoolClass:
        default:
            return ApproveDenyModalContents.has(changeType)
                ? ApproveDenyModalContents.get(changeType)
                : null;
    }
}

export function getEventModalOptinalStyle(target: ChangeLogType, changeType) {
    switch (target) {
        case ChangeLogType.School:
            return SchoolApproveDenyModalOptinalStyle.has(changeType)
                ? SchoolApproveDenyModalOptinalStyle.get(changeType)
                : {};
        case ChangeLogType.Campus:
            return CampusApproveDenyModalOptinalStyle.has(changeType)
                ? CampusApproveDenyModalOptinalStyle.get(changeType)
                : {};
        case ChangeLogType.SchoolClass:
        default:
            return ApproveDenyModalOptinalStyle.has(changeType)
                ? ApproveDenyModalOptinalStyle.get(changeType)
                : {};
    }
}

export const ApproveDenyModal: React.FC<ApproveDenyModalContentProps<
    ChangeEventModel
>> = (props: ApproveDenyModalContentProps<ChangeEventModel>) => {
    const { target, changeType, visible, loading, item, onCancel } = props;
    const displayHeader = ApproveDenyModalHideClassName.has(changeType)
        ? ApproveDenyModalHideClassName.get(changeType)
        : true;
    const campusDisplayHeader = ApproveDenyCampusModalHideClassName.has(
        changeType
    )
        ? ApproveDenyCampusModalHideClassName.get(changeType)
        : true;
    const ModalContent = getApproveDenyModalContents(target, changeType);
    const modalOptionalStyle = getEventModalOptinalStyle(target, changeType);
    const modalClassNames = {
        "change-event-modal": true,
        "approve-deny-modal": true,
        "align-items-right": true,
        ...modalOptionalStyle
    };
    const lastChangeItem = maxBy(
        item["changeData"] || item,
        ChangeEventPropsModel.createdDate
    );

    return (
        <Modal
            title={<EventModalTitle {...{ ...props, lastChangeItem }} />}
            footer={null}
            className={classNames(modalClassNames)}
            visible={visible}
            destroyOnClose={true}
            onCancel={onCancel}
        >
            <Spin spinning={loading}>
                {campusDisplayHeader && displayHeader && (
                    <EventModalHeader {...props} />
                )}
                {ModalContent && <ModalContent {...props} />}
                {canApproveDeny4ChangeType(target, changeType) && (
                    <EventModalFooter {...{ ...props, lastChangeItem }} />
                )}
            </Spin>
        </Modal>
    );
};

function getEventModalTitleIds(target: ChangeLogType, changeType) {
    switch (target) {
        case ChangeLogType.School:
            return SchoolApproveDenyModalTitleIds.has(changeType)
                ? SchoolApproveDenyModalTitleIds.get(changeType)
                : null;
        case ChangeLogType.Campus:
            return CampusApproveDenyModalTitleIds.has(changeType)
                ? CampusApproveDenyModalTitleIds.get(changeType)
                : null;
        case ChangeLogType.SchoolClass:
        default:
            return ApproveDenyModalTitleIds.has(changeType)
                ? ApproveDenyModalTitleIds.get(changeType)
                : null;
    }
}
const EventModalTitle: React.FC<ApproveDenyModalContentProps<
    ChangeEventModel
>> = (props: ApproveDenyModalContentProps<ChangeEventModel>) => {
    const { target, changeType, lastChangeItem, showCloseButton, verificationCIMSId, subTitle } = props;
    const title = getEventModalTitleIds(target, changeType);
    return lastChangeItem ? (
        <React.Fragment>
            <div className="change-event-modal-title">
                <span>{fmtMsg({ id: title })}</span>
            </div>
            <div className="change-event-modal-subtitle">
                <div className="change-event-modal-subtitle__studentverify">
                    {verificationCIMSId &&
                        <FormItem
                            {...FormItemLayout4ApproveDenyTitle}
                            label={fmtMsg({ id: SchoolLocale.CIMSChangeEventStatusText })}
                        >
                            <span className="ant-form-text">
                                {showCloseButton ? fmtMsg({ id: SchoolLocale.CIMSChangeEventApprovedText }) : fmtMsg({ id: SchoolLocale.CIMSChangeEventPendingText })}
                            </span>
                        </FormItem>
                    }
                    {
                        verificationCIMSId &&
                        <FormItem
                            {...FormItemLayout4ApproveDenyTitle}
                            label={fmtMsg({ id: SchoolLocale.CIMSChangeEventClassText })}
                        >
                            <span className="ant-form-text">
                                {subTitle}
                            </span>
                        </FormItem>
                    }
                </div>
                <FormItem
                    {...FormItemLayout4ApproveDenyTitle}
                    label={fmtMsg({
                        id: SchoolLocale.CIMSChangeEventModifyDate
                    })}
                >
                    <span className="ant-form-text">
                        {lastChangeItem.createdDate}
                    </span>
                </FormItem>
                <FormItem
                    {...FormItemLayout4ApproveDenyTitle}
                    label={fmtMsg({ id: SchoolLocale.CIMSChangeEventModifyby })}
                >
                    <span className="ant-form-text">
                        {lastChangeItem.createdBy}
                    </span>
                </FormItem>
            </div>
        </React.Fragment>
    ) : null;
};

function getSubTitle(target: ChangeLogType, changeType) {
    switch (target) {
        case ChangeLogType.School:
            return fmtMsg({ id: SchoolLocale.CIMSChangeEventSchoolSubTitle });
        case ChangeLogType.Campus:
            return changeType == EventInfo.PendingChangesMaterialOrder
                ? fmtMsg({
                    id: SchoolLocale.CIMSChangeEventCampusMaterialSubTitle
                })
                : fmtMsg({ id: SchoolLocale.CIMSChangeEventCampusSubTitle });
        case ChangeLogType.SchoolClass:
        default:
            return fmtMsg({ id: SchoolLocale.CIMSChangeEventClassName });
    }
}

export const EventModalHeader: React.FC<ApproveDenyModalContentProps<
    ChangeEventModel
>> = (props: ApproveDenyModalContentProps<ChangeEventModel>) => {
    const { target, subTitle, changeType, verificationCIMSId } = props;
    const headerClassNames = {
        "change-event-modal-header": true,
        "school-campus-header": target != ChangeLogType.SchoolClass
    };
    const subTitleLabel = getSubTitle(target, changeType);
    return (!verificationCIMSId &&
        <div className={classNames(headerClassNames)}>
            <FormItemsLayout colTotal={1}>
                <FormItem {...FormItemLayout4ClassName} label={subTitleLabel}>
                    <span className="ant-form-text">{subTitle}</span>
                </FormItem>
            </FormItemsLayout>
        </div>
    );
};

interface EventModalFooterProps {
    order: MaterialOrderModel;
    materialOrderId: string;
    schoolClassName: string
}

@GLForm.create()
@connect(({ material: { order } }: StateType) => ({ order }))
export class EventModalFooter extends Component<
ApproveDenyModalContentProps<ChangeEventModel> &
GLFormComponentProps &
EventModalFooterProps,
{ displayUndoReson: boolean }
> {
    constructor(props) {
        super(props);
        this.state = {
            displayUndoReson: false
        };
    }

    onPrepareUndo(e) {
        e.preventDefault();
        const { materialOrderId, order } = this.props;
        const shippingStatus = order ? order.status : -1;
        if (
            materialOrderId &&
            shippingStatus === MaterialOrderStatus.Shipped
        ) {
            message.info(fmtMsg({ id: SchoolLocale.CIMSMsgMaterialShipped }));
            return;
        }
        this.setState({ displayUndoReson: !this.state.displayUndoReson });
    }
    render() {
        const { target, form, lastChangeItem, onApprove, onDeny, onCancel, schoolClassName } = this.props;
        const contentClassName = {
            "undo-content": true,
            "undo-content-hidden": !this.state.displayUndoReson
        };
        return (
            <React.Fragment>
                {/* {this.state.displayUndoReson &&
                    <Divider type='horizontal' className='form-approve-deny-divider' />
                } */}
                <Form className='form-approve-deny' onSubmit={onApprove} >
                    {this.props.showCloseButton &&
                        <CloseButtons onCancel={onCancel} />
                    }
                    {!this.state.displayUndoReson && !this.props.showCloseButton &&
                        <ApproveUndoButtons onPrepareUndo={this.onPrepareUndo.bind(this)} />
                    }
                    <UndoContent
                        target={target}
                        contentClassName={contentClassName}
                        schoolClassName={schoolClassName}
                        form={form}
                        lastChangeItem={lastChangeItem}
                        onPrepareUndo={this.onPrepareUndo.bind(this)}
                        onUndo={event => {
                            event.preventDefault();
                            form.validateFields((err, values) => {
                                !err && onDeny(values);
                            });
                        }}
                    />
                </Form>
            </React.Fragment>
        );
    }
}

const ApproveUndoButtons = ({ onPrepareUndo }) => {
    const approveText = fmtMsg({
        id: SchoolLocale.CIMSPendingChangesApproveAction
    });
    const undoText = fmtMsg({ id: SchoolLocale.CIMSPendingChangesDenyAction });
    return (
        <div className="submit-btns">
            <div>
                <div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        title={approveText}
                    >
                        {approveText}
                    </Button>
                </div>
                <div>
                    <Button title={undoText} onClick={onPrepareUndo}>
                        <ArrowDownIcon />
                        {undoText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const CloseButtons = ({ onCancel }) => {
    const closeText = fmtMsg({ id: SchoolLocale.CIMSPendingChangesCancelAction });
    return (
        <div className='submit-btns'>
            <div>
                <div><Button onClick={onCancel} type="default" htmlType="button" title={closeText}>{closeText}</Button></div>
            </div>
        </div>
    )
}

const UndoButtons = ({ onPrepareUndo, onUndo }) => {
    const cancelText = fmtMsg({ id: GLLocale.Cancel });
    const submitText = fmtMsg({ id: GSAdminLocale.ButtonSubmit });
    return (
        <div className="submit-btns">
            <div>
                <div>
                    <Button title={cancelText} onClick={onPrepareUndo}>
                        <ArrowUpIcon />
                        {cancelText}
                    </Button>
                </div>
                <div>
                    <Button type="primary" title={submitText} onClick={onUndo}>
                        {submitText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const UndoContent = ({
    target,
    contentClassName,
    form,
    lastChangeItem,
    onPrepareUndo,
    onUndo,
    schoolClassName
}) => {
    const getParamValue = target => {
        switch (target) {
            case ChangeLogType.School:
                return lastChangeItem && lastChangeItem.schoolName
                    ? lastChangeItem.schoolName
                    : "";
            case ChangeLogType.Campus:
                return lastChangeItem && lastChangeItem.campusName
                    ? lastChangeItem.campusName
                    : "";
            case ChangeLogType.SchoolClass:
            default:
                const className =
                    lastChangeItem && lastChangeItem.schoolClassName
                        ? lastChangeItem.schoolClassName
                        : lastChangeItem &&
                            lastChangeItem.targetClass &&
                            lastChangeItem.targetClass.name
                            ? lastChangeItem.targetClass.name
                            : "";
                if (!className) {
                    return schoolClassName;
                }
                return className;
        }
    };
    const className = getParamValue(target);
    const userName =
        lastChangeItem && lastChangeItem.createdBy
            ? lastChangeItem.createdBy
            : "";
    const reason = fmtMsg(
        { id: SchoolLocale.CIMSPendingChangesDenyReasonDefaultText },
        { className }
    );
    const noteRules: ValidationRule[] = textareaValider(form, "reason").concat([
        FormHelper.ruleForCompareLength(
            SchoolLocale.CIMSPendingChangesDenyReasonLabel,
            ComparisonOperator.LessOrEqualsThan,
            500
        )
    ]);
    noteRules.push({
        required: true,
        message: fmtMsg({
            id: SchoolLocale.CIMSPendingChangesDenyReasonRequired
        })
    });
    const getDenyTitleId = target => {
        switch (target) {
            case ChangeLogType.School:
                return SchoolLocale.CIMSPendingChangesDenyTitle4School;
            case ChangeLogType.Campus:
                return SchoolLocale.CIMSPendingChangesDenyTitle4Campus;
            case ChangeLogType.SchoolClass:
            default:
                return SchoolLocale.CIMSPendingChangesDenyTitle;
        }
    };
    const getNotifyMessageId = target => {
        switch (target) {
            case ChangeLogType.School:
                return SchoolLocale.CIMSPendingChangesDenyNotifySchoolAdmin;
            case ChangeLogType.Campus:
            case ChangeLogType.SchoolClass:
            default:
                return SchoolLocale.CIMSPendingChangesDenyNotifyAdmin;
        }
    };
    return (
        <div className={classNames(contentClassName)}>
            <h3 className="undo-content-title">
                <FormattedMessage id={getDenyTitleId(target)} />
            </h3>
            <div className="undo-notes">
                <h4 className="undo-notes-required">
                    <FormattedMessage
                        id={SchoolLocale.CIMSPendingChangesDenyReasonLabel}
                    />
                </h4>
                <Form.Item>
                    {form.getFieldDecorator("reason", {
                        initialValue: reason,
                        rules: noteRules,
                        validateTrigger: "onBlur"
                    })(<Input.TextArea maxLength={500} />)}
                </Form.Item>
            </div>
            <div className="undo-usergroups">
                <h5>
                    <FormattedMessage
                        id={SchoolLocale.CIMSPendingChangesDenyNotifyUser}
                        values={{ userName }}
                    />
                </h5>
                <Form.Item>
                    {form.getFieldDecorator("notifySchoolAdminAndCampusAdmin", {
                        valuePropName: "checked"
                    })(
                        <Checkbox>
                            <FormattedMessage id={getNotifyMessageId(target)} />
                        </Checkbox>
                    )}
                </Form.Item>
            </div>
            <UndoButtons onPrepareUndo={onPrepareUndo} onUndo={onUndo} />
        </div>
    );
};
