import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { Table, Divider } from "antd-min";
import { EventModalContentProps, MandatoryAttributeModel, PendingChangePropsModel } from '@app/service/cims';
import { fmtMsg, DateHelper, PendingChangeField, LicenseTypeValueNameMap, CONSTS } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItem, FormItemLayout, ClassAttribute2DisplayLabel } from './cims-consts';
import { ArrowRightIcon } from '@app/components/svgicon';

export const MandatoryAttributeComparer: React.FC<EventModalContentProps<MandatoryAttributeModel>> = (props: EventModalContentProps<MandatoryAttributeModel>) => {
    const { item } = props;
    const data = Array.isArray(item) && item.length > 0 ? item[0] : item;
    const previousClass = data.previousClass ? data.previousClass : {...data};
    const currentClass = data.currentClass ? data.currentClass : {...data}
    const changedFields = data.changeFields;
    const ageGradeChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.Grade)) 
                                                || (!changedFields && previousClass.age != currentClass.age));
    const tsiPerWeekChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.TSILessonsPerWeek))
                                                || (!changedFields && previousClass.tsiLessonsPerWeek != currentClass.tsiLessonsPerWeek));
    const startUnitChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.StartUnit))
                                                || (!changedFields && previousClass.startUnit != currentClass.startUnit));
    const endUnitChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.EndUnit))
                                                || (!changedFields && previousClass.endUnit != currentClass.endUnit));
    const startDateChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.StartDate))
                                                || (!changedFields && previousClass.startDate != currentClass.startDate));
    const endDateChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.EndDate))
                                                || (!changedFields && previousClass.endDate != currentClass.endDate));
    const licenseTypeChanged = previousClass && ((changedFields && changedFields.includes(PendingChangeField.LicenseType))
                                                || (!changedFields && previousClass.licenseType != currentClass.licenseType));
    function isMandatoryAttribute(attribute) {
        return (ageGradeChanged && attribute == PendingChangePropsModel.age) 
            || (tsiPerWeekChanged && attribute == PendingChangePropsModel.tsiLessonsPerWeek) 
            || (startUnitChanged && attribute == PendingChangePropsModel.startUnit4Display)
            || (endUnitChanged && attribute == PendingChangePropsModel.endUnit4Display) 
            || (startDateChanged && attribute == PendingChangePropsModel.startDate4Display) 
            || (endDateChanged && attribute == PendingChangePropsModel.endDate4Display)
            || (licenseTypeChanged && attribute == PendingChangePropsModel.licenseType4Display);
    }
    function formatAttribute4Display(classInfo) {
        classInfo[PendingChangePropsModel.startDate4Display] = classInfo.startDate ? DateHelper.formatDate2Local(classInfo.startDate) : null;
        classInfo[PendingChangePropsModel.endDate4Display] = classInfo.endDate ? DateHelper.formatDate2Local(classInfo.endDate) : null;
        classInfo[PendingChangePropsModel.startUnit4Display] = classInfo.startUnit ? Math.abs(classInfo.startUnit) : null;
        classInfo[PendingChangePropsModel.endUnit4Display] = classInfo.endUnit ? Math.abs(classInfo.endUnit) : null;
        classInfo[PendingChangePropsModel.licenseType4Display] = classInfo.licenseType ? fmtMsg({id: CONSTS.LicenseType.get(classInfo.licenseType)}) : null;
        return classInfo;
    }
    function formatMandatoryAttribute(classInfo) {
        return Object.keys(classInfo).filter(key => isMandatoryAttribute(key))
                    .map((attribute, index) => {
                        return {
                            index, 
                            mandatoryAttribute: fmtMsg({id: ClassAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}), 
                            mandatoryAttributeValue: classInfo[attribute]
                }});
    }
    return (
        <div className='mandatory-attribute-comparer'>
            <FormItemsLayout colTotal={2} >
                <div className='mandatory-attribute-comparer-divider'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventPrevious})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrent})}</Divider>
                </div>
            </FormItemsLayout>
            <FormItemsLayout colTotal={2} >
                <FormItem>
                    <MandatoryAttributeTable dataSource={formatMandatoryAttribute(formatAttribute4Display(previousClass))} />
                </FormItem>
                <ArrowRightIcon className='previous-current-comparer-arrow' />
                <FormItem>
                    <MandatoryAttributeTable dataSource={formatMandatoryAttribute(formatAttribute4Display(currentClass))} />
                </FormItem>
            </FormItemsLayout>
        </div>
    )
}

const MandatoryAttributeTable = ({dataSource}) => {
    return (
        <Table
            rowKey='index'
            showHeader={false}
            pagination={false}
            scroll={{y: 200}}
            dataSource={dataSource}
            columns={[
                {
                    title: '',
                    dataIndex: "mandatoryAttribute",
                    width: '47%',
                    className: 'mandatory-attribute'
                }, {
                    title: '',
                    dataIndex: "mandatoryAttributeValue",
                    width: '53%'
            }]}
        />
    )
}