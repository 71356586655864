import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { Divider } from "antd-min";
import { EventModalContentProps, SchoolMandatoryAttributeModel, PendingChangePropsModel } from '@app/service/cims';
import { fmtMsg, SchoolChangeField } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { SchoolAttribute2DisplayLabel } from './cims-consts';
import { MandatoryAttributeTable } from './mandatory-attribute-table';

export const SchoolMandatoryAttributeComparer: React.FC<EventModalContentProps<SchoolMandatoryAttributeModel>> = (props: EventModalContentProps<SchoolMandatoryAttributeModel>) => {
    const { item } = props;
    const data = Array.isArray(item) && item.length > 0 ? item[0] : item;
    const previousSchool = data.previousSchool ? data.previousSchool : {...data};
    const currentSchool = data.currentSchool ? data.currentSchool : {...data}
    const changedFields = data.changeFields;
    const nameChanged = previousSchool && ((changedFields && changedFields.includes(SchoolChangeField.Name)) 
                                                || (!changedFields && previousSchool.name != currentSchool.name));
    const englishNameChanged = previousSchool && ((changedFields && changedFields.includes(SchoolChangeField.EnglishName))
                                                || (!changedFields && previousSchool.englishName != currentSchool.englishName));
    function isMandatoryAttribute(attribute) {
        return (nameChanged && attribute == PendingChangePropsModel.name) 
            || (englishNameChanged && attribute == PendingChangePropsModel.englishName);
    }
    function formatMandatoryAttribute(preSchoolInfo, curSchoolInfo) {
        return Object.keys(preSchoolInfo).filter(key => isMandatoryAttribute(key))
                    .map((attribute, index) => {
                        return {
                            index, 
                            preMandatoryAttribute: fmtMsg({id: SchoolAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}), 
                            preMandatoryAttributeValue: preSchoolInfo[attribute], 
                            curMandatoryAttribute: fmtMsg({id: SchoolAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}), 
                            curMandatoryAttributeValue: curSchoolInfo[attribute]
                }});
    }
    return (
        <div className='mandatory-attribute-comparer'>
            <FormItemsLayout colTotal={2} >
                <div className='mandatory-attribute-comparer-divider mandatory-attribute-comparer-divider-with-table'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventPrevious})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventCurrent})}</Divider>
                </div>
            </FormItemsLayout>
            <MandatoryAttributeTable dataSource={formatMandatoryAttribute(previousSchool, currentSchool)} />
        </div>
    )
}