import React, { Component } from 'react';
import { FormItemsLayout } from 'gl-commonui';
import { Divider } from "antd-min";
import { EventModalContentProps, MandatoryAttributeModel, PendingChangePropsModel } from '@app/service/cims';
import { fmtMsg, DateHelper, SchoolHelper, LicenseTypeValueNameMap, CONSTS } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { ClassAttribute2DisplayLabel } from './cims-consts';
import { MandatoryAttributeTable } from './mandatory-attribute-table';

export const PromoteClassComparer: React.FC<EventModalContentProps<MandatoryAttributeModel>> = (props: EventModalContentProps<MandatoryAttributeModel>) => {
    const { item } = props;
    const data = Array.isArray(item) && item.length > 0 ? item[0] : item;
    const sourceClass = data.sourceClass ? data.sourceClass : {...data};
    const targetClass = data.targetClass ? data.targetClass : {...data}
    const schoolCurriculumType = SchoolHelper.generateSchoolCurriculumType();
    const attributeOrder: Map<PendingChangePropsModel, number> = new Map([
        [PendingChangePropsModel.name, 1],
        [PendingChangePropsModel.curriculumType4Display, 2],
        [PendingChangePropsModel.licenseType4Display, 3],
        [PendingChangePropsModel.age, 4],
        [PendingChangePropsModel.startUnit4Display, 5],
        [PendingChangePropsModel.endUnit4Display, 6],
        [PendingChangePropsModel.startDate4Display, 7],
        [PendingChangePropsModel.endDate4Display, 8],
        [PendingChangePropsModel.tsiLessonsPerWeek, 9],
    ]);

    function isComparerAttribute(attribute) {
        return attribute == PendingChangePropsModel.name
            || attribute == PendingChangePropsModel.age
            || attribute == PendingChangePropsModel.curriculumType4Display
            || attribute == PendingChangePropsModel.licenseType4Display
            || attribute == PendingChangePropsModel.startUnit4Display
            || attribute == PendingChangePropsModel.endUnit4Display
            || attribute == PendingChangePropsModel.startDate4Display
            || attribute == PendingChangePropsModel.endDate4Display
            || attribute == PendingChangePropsModel.tsiLessonsPerWeek
    }

    function formatAttribute4Display(classInfo) {
        classInfo[PendingChangePropsModel.curriculumType4Display] = schoolCurriculumType.get(`${classInfo.curriculumType}`);
        classInfo[PendingChangePropsModel.licenseType4Display] = classInfo.licenseType && fmtMsg({id: CONSTS.LicenseType.get(classInfo.licenseType)});
        classInfo[PendingChangePropsModel.startUnit4Display] = classInfo.startUnit ? Math.abs(classInfo.startUnit) : null;
        classInfo[PendingChangePropsModel.endUnit4Display] = classInfo.endUnit ? Math.abs(classInfo.endUnit) : null;
        classInfo[PendingChangePropsModel.startDate4Display] = classInfo.startDate ? DateHelper.toLocalStringFromUTC(classInfo.startDate) : null;
        classInfo[PendingChangePropsModel.endDate4Display] = classInfo.endDate ? DateHelper.toLocalStringFromUTC(classInfo.endDate) : null;
        return classInfo;
    }

    function formatMandatoryAttribute(preClassInfo, curClassInfo) {
        return Object.keys(preClassInfo)
                    .filter(key => isComparerAttribute(key))
                    .map((attribute, index) => {
                        return {
                            index,
                            attribute,
                            preMandatoryAttribute: fmtMsg({id: ClassAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}),
                            preMandatoryAttributeValue: preClassInfo[attribute], 
                            curMandatoryAttribute: fmtMsg({id: ClassAttribute2DisplayLabel.get(attribute as PendingChangePropsModel)}), 
                            curMandatoryAttributeValue: curClassInfo[attribute]                            
                    }})
                    .sort((a, b) => attributeOrder.get(a.attribute as PendingChangePropsModel) - attributeOrder.get(b.attribute as PendingChangePropsModel));
    }    
    return (
        <div className='mandatory-attribute-comparer promote-class-comparer'>
            <FormItemsLayout colTotal={2} >
                <div className='mandatory-attribute-comparer-divider'>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventSourceClass})}</Divider>
                    <Divider orientation="left">{fmtMsg({id:SchoolLocale.CIMSChangeEventTargetClass})}</Divider>
                </div>
            </FormItemsLayout>
            <MandatoryAttributeTable 
                columnWidth = {['18%', '27%', '10%', '18%', '27%']}
                dataSource = {formatMandatoryAttribute(formatAttribute4Display(sourceClass), formatAttribute4Display(targetClass))} 
            />
        </div>
    )
}