import React, { useEffect, useState } from "react";
import { EventModalContentProps, ChangeEventModel } from "@app/service/cims";
import { connect, GLUtil, StateType } from "gl-commonui";
import { getMaterialOrder } from "@app/states/material";
import {
    SubscriptionRequestModel,
    SubscriptionItemDisplayModel,
    RequestProductItemModel,
    MaterialOrdersModel
} from "@app/service/material";
import {
    PurchaseRequest,
    SubscriptionClass
} from "@app/page/school/material/components";
import { SchoolLocale } from "@app/locales/localeid";
import { Loading } from "@app/components";
import { Col, Row } from "antd-min";
import { fmtMsg, formatToMaterialRequestId, MaterialOrderStatus } from "@app/util";
import { Link } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import { History } from "history";

interface MaterialInfoCompProps {
    regionId: string;
    schoolId: string;
    campusId: string;
    order: MaterialOrdersModel;
    materialOrderId: string;
    materialRequestId: string;
    subscriptionRequest: SubscriptionRequestModel;
    subscriptions: SubscriptionItemDisplayModel[];
    purchases: RequestProductItemModel[];
    history: History;
    error: any;
    getMaterialOrder: (arg: {
        schoolId: string;
        campusId: string;
        orderId: string;
    }) => void;
}

const MaterialInfoComp: React.FC<EventModalContentProps<ChangeEventModel> &
    MaterialInfoCompProps> = (
        props: EventModalContentProps<ChangeEventModel> & MaterialInfoCompProps
    ) => {
        const {
            subscriptions,
            purchases,
            regionId,
            schoolId,
            campusId,
            order,
            materialOrderId: orderId,
            getMaterialOrder
        } = props;

        const [loading, setLoading] = useState(false);

        useEffect(() => {
            !loading && setLoading(true);
            getMaterialOrder({ schoolId, campusId, orderId });
        }, []);

        useEffect(() => {
            if (order && orderId == order.id) {
                loading && setLoading(false);
            }
        });

        if (loading) {
            return <Loading visible={true} />;
        }

        let requestId: any = "NA";
        if (order && order.materialRequestId) {
            requestId = formatToMaterialRequestId(order.materialRequestId);
            if (regionId && schoolId && campusId && order.status) {
                if (parseInt(order.status, 10) === MaterialOrderStatus.Draft) {
                    requestId = <Link
                        to={GLUtil.pathStringify(
                            PathConfig.CampusOrdersDraftItem,
                            {
                                regionId,
                                schoolId,
                                campusId,
                                orderId: order.id
                            }
                        )}
                    >
                        {formatToMaterialRequestId(order.materialRequestId)}
                    </Link>
                }
                else if (parseInt(order.status, 10) !== MaterialOrderStatus.Initialized) {
                    requestId = <Link
                        to={GLUtil.pathStringify(
                            PathConfig.CampusOrdersItem,
                            {
                                regionId,
                                schoolId,
                                campusId,
                                orderId: order.id
                            }
                        )}
                    >
                        {formatToMaterialRequestId(order.materialRequestId)}
                    </Link>
                }
            }
        }

        const campus = order && order.campusName ? order.campusName : "NA";
        const email = order && order.submissionEmail ? order.submissionEmail : "NA";
        const phone = order && order.submissionPhone ? order.submissionPhone : "NA";

        return (
            <div className="material-info">
                <Row type="flex" gutter={10} className="material-info__o-info">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <span className="material-info__label">
                            {fmtMsg({ id: SchoolLocale.CIMSMaterialOrderId })}:
                        </span>{" "}
                        {requestId}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <span className="material-info__label">
                            {fmtMsg({ id: SchoolLocale.CIMSMaterialCampus })}:
                        </span>{" "}
                        {campus}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <span className="material-info__label">
                            {fmtMsg({ id: SchoolLocale.CIMSMaterialEmail })}:
                        </span>{" "}
                        {email}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <span className="material-info__label">
                            {fmtMsg({ id: SchoolLocale.CIMSMaterialPhone })}:
                        </span>{" "}
                        {phone}
                    </Col>
                </Row>
                {subscriptions.length > 0 && (
                    <div>
                        <SubscriptionClass
                            showClassAsLink={false}
                            stickyHeaders={false}
                            canEdit={false}
                            canEditQuantity={false}
                            needShippedQuantityColumn={true}
                            locationParams={{ regionId, schoolId, campusId }}
                            clearShippedValue={false}
                            dataSource={subscriptions}
                            isGridEditing={false}
                            shippedQuantityIsRequired={true}
                            needTotalRow={true}
                            history={props.history}
                        />
                    </div>
                )}
                {purchases.length > 0 && (
                    <PurchaseRequest
                        showClassAsLink={false}
                        stickyHeaders={false}
                        canEdit={false}
                        canEditQuantity={false}
                        requestTitleId={SchoolLocale.MaterialPurchaseItemListTitle}
                        requests={purchases}
                        dataSource={purchases}
                        needClassColumn={true}
                        needPriceColumn={true}
                        needShippedQuantityColumn={true}
                        isGridEditing={false}
                        needTotalPriceColumn={true}
                        needTotalRow={true}
                        clearShippedValue={false}
                        locationParams={{ regionId, schoolId, campusId }}
                        shippedQuantityIsRequired={true}
                        showNoteColumn={true}
                        history={props.history}
                    />
                )}
            </div>
        );
    };

export const MaterialInfo = connect(
    ({ material: { subscriptions, purchases, order } }: StateType) => ({
        subscriptions,
        purchases,
        order
    }),
    {
        getMaterialOrder
    }
)(MaterialInfoComp);
