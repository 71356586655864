import React, { Component } from 'react';
import { EventModalContentProps, MovePromoteEventPropsModel, MovePromoteStudentInfo, MovePromoteStudentsModel } from '@app/service/cims';
import { flatten, isEmpty } from 'lodash';
import { DateHelper, fmtMsg, GSSchoolAction, MovePromoteOperationType } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';
import { Grid } from '@app/components/grid/grid';
import { Column } from '@app/components/grid/grid-column';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { ColumnLink } from '@app/components';


export const MovePromoteStudentsCimsInfo: React.FC<EventModalContentProps<MovePromoteStudentsModel>> = (props: EventModalContentProps<MovePromoteStudentsModel>) => {
    const { item } = props;
    const data = item ? Array.isArray(item) && item.length > 0 ? flatten(item.map(e => e.students.map(s => s))) : isEmpty(item) ? [] : item.students : [];
    return (
        <div className='move-promote-students-cims-info'>
            <Grid className="grid" itemsSource={data} pagination={false} stickyHeaders={false}>
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteStudentNameColumn })} binding={MovePromoteEventPropsModel.name} />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteStudentEnglishNameColumn })} binding={MovePromoteEventPropsModel.englishName} />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteCreatedDateColumn })} binding={MovePromoteEventPropsModel.createdDate}
                    render={(text, record, index) => {
                        return DateHelper.toLocalStringFromUTC(record.createdDate);
                    }}
                />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteOperationTypeColumn })} align="left" allowSorting={false} binding={MovePromoteEventPropsModel.operationType}
                    render={(text, record, index) => {
                        return record.operationType == null ? "" :
                            fmtMsg({
                                id: record.operationType === MovePromoteOperationType.Move ?
                                    SchoolLocale.CIMSStudentMovePromoteOperationTypeMove : record.operationType === MovePromoteOperationType.Promote ?
                                        SchoolLocale.CIMSStudentMovePromoteOperationTypePromoted : record.operationType === MovePromoteOperationType.MovePromoted ?
                                            SchoolLocale.CIMSStudentMovePromoteOperationTypeMovePromoted : SchoolLocale.CIMSStudentMovePromoteOperationTypeRevertPromoted
                            });
                    }}
                />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteParentClassColumn })} binding={MovePromoteEventPropsModel.parentClassName}
                    render={(text, pendingChange: MovePromoteStudentInfo, index) => {
                        if (!text) return "";
                        const { parentRegionId: regionId, parentSchoolId: schoolId,
                            parentCampusId: campusId, parentClassId: classId } = pendingChange
                        if (
                            GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                            regionId &&
                            schoolId &&
                            campusId &&
                            classId
                        ) {
                            return <ColumnLink
                                history={history as any}
                                url={GLUtil.pathStringify(
                                    PathConfig.Students,
                                    { regionId, schoolId, campusId, classId }
                                )}
                            >
                                {text}
                            </ColumnLink>
                        } else {
                            return `<span>${text}</span>`;
                        }
                    }}
                />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromotePreviousClassColumn })} binding={MovePromoteEventPropsModel.previousClassName}
                    render={(text, pendingChange: MovePromoteStudentInfo, index) => {
                        if (!text) return "";
                        const { previousRegionId: regionId, previousSchoolId: schoolId,
                            previousCampusId: campusId, previousClassId: classId } = pendingChange
                        if (
                            GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                            regionId &&
                            schoolId &&
                            campusId &&
                            classId
                        ) {
                            return <ColumnLink
                                history={history as any}
                                url={GLUtil.pathStringify(
                                    PathConfig.Students,
                                    { regionId, schoolId, campusId, classId }
                                )}
                            >
                                {text}
                            </ColumnLink>
                        } else {
                            return `<span>${text}</span>`;
                        }
                    }} />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteCurrentClassColumn })} binding={MovePromoteEventPropsModel.currentClassName}
                    render={(text, pendingChange: MovePromoteStudentInfo, index) => {
                        if (!text) return "";
                        const { currentRegionId: regionId, currentSchoolId: schoolId,
                            currentCampusId: campusId, currentClassId: classId } = pendingChange
                        if (
                            GLGlobal.isActionValid(GSSchoolAction.Campuses) &&
                            regionId &&
                            schoolId &&
                            campusId &&
                            classId
                        ) {
                            return <ColumnLink
                                history={history as any}
                                url={GLUtil.pathStringify(
                                    PathConfig.Students,
                                    { regionId, schoolId, campusId, classId }
                                )}
                            >
                                {text}
                            </ColumnLink>
                        } else {
                            return `<span>${text}</span>`;
                        }
                    }} />
                <Column header={fmtMsg({ id: SchoolLocale.CIMSStudentMovePromoteNoteColumn })} binding={MovePromoteEventPropsModel.note} />
            </Grid>
        </div>
    )
}


